import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { ROUTES } from '~/routes/routes';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';
import { DropdownArrow } from '~/views/atoms/svg-icons/dropdown-arrow';
import { SupportMenuItem } from '~/views/enums';
export const SupportMenu = observer((props) => {
    let selectedPage = props.activeItem;
    const navigate = useNavigate();
    const onAccountPageChange = useCallback(() => {
        const path = selectedPage === SupportMenuItem.support ? ROUTES.MESSAGES.path : ROUTES.SUPPORT.path;
        navigate(path);
    }, [navigate]);
    return (_jsxs("div", { className: "account-menu support-menu", children: [_jsx("h3", { children: "Support" }), false && (_jsxs("div", { className: "account-menu-inner", children: [_jsxs("div", { className: "account-menu-item", children: [_jsxs("figure", { children: [props.activeItem === SupportMenuItem.support && (_jsx("img", { src: "/assets/img/faq-active.svg" })), props.activeItem !== SupportMenuItem.support && (_jsx("img", { src: "/assets/img/faq.svg" }))] }), _jsx(Link, { className: props.activeItem === SupportMenuItem.support ? "account-active" : "", to: ROUTES.SUPPORT.path, children: "FAQ" })] }), _jsxs("div", { className: "account-menu-item", children: [_jsxs("figure", { children: [props.activeItem === SupportMenuItem.messages && (_jsx("img", { src: "/assets/img/messages-icon-active.svg" })), props.activeItem !== SupportMenuItem.messages && (_jsx("img", { src: "/assets/img/messages-icon.svg" }))] }), _jsx(Link, { className: props.activeItem === SupportMenuItem.messages ? "account-active" : "", to: ROUTES.MESSAGES.path, children: "Messages center" })] })] })), false && (_jsx("div", { className: "account-menu-select", children: _jsxs(Select, { className: "hr-select", IconComponent: DropdownArrow, value: selectedPage, onChange: onAccountPageChange, children: [_jsx(MenuItem, { value: SupportMenuItem.support, children: "FAQ" }), _jsx(MenuItem, { value: SupportMenuItem.messages, children: "Messages center" })] }) }))] }));
});
