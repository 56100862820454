import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
export const BackBtn = props => {
    const navigate = useNavigate();
    const goBack = () => {
        if (props.beforeGoBack) {
            props.beforeGoBack();
        }
        navigate(props.path);
    };
    return (_jsx("div", { className: "go-back", children: _jsx("div", { className: "go-back-btn-wrap", children: _jsxs("button", { id: "go-back-btn", type: "button", className: "btn", onClick: goBack, children: [_jsx("img", { src: "/assets/img/arrow-left.svg" }), _jsx("span", { children: "Go back" })] }) }) }));
};
