var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Injectable } from './service.base';
import { action, makeObservable, observable } from 'mobx';
import { plainToInstance } from 'class-transformer';
import { api } from '~/tools/api/api';
import { EApiUrl } from '~/tools/api/enums';
import { ModelSate } from '~/models/model.state';
import jsonStates from '~/data/states.json';
export let PaysafeFormService = class PaysafeFormService {
    cardNumberValid = false;
    cardNumberError = false;
    cardNumberErrorText = '';
    expiryDateValid = false;
    expiryDateError = false;
    expiryDateErrorText = '';
    cvcValid = false;
    cvcError = false;
    cvcErrorText = '';
    isCardHolderInvalid = false;
    cardHolderErrorText = '';
    btnDisabled = true;
    cardSaveOptionChecked = true;
    addNewShowLoading = false;
    addSelectedCardShowLoading = false;
    confirmAndPayDisabled = true;
    paysafeErrorSelectedCard = '';
    creditCardBrand = '';
    paysafeFieldsError = false;
    zipError = false;
    stateError = false;
    selectedState = '0';
    testAmount = null;
    cardHolder = '';
    zip = '';
    creditCards = observable.array([]);
    selectedCreditCard;
    paysafejsApiKey = process.env.PAYSAFEJS_API_KEY ? process.env.PAYSAFEJS_API_KEY : '';
    paysafeEnv = process.env.PAYSAFEJS_ENV ? process.env.PAYSAFEJS_ENV : 'TEST';
    paysafeDefaultAccount = process.env.PAYSAFEJS_ACCOUNT ? process.env.PAYSAFEJS_ACCOUNT : 0;
    paysafeInstance = null;
    selectedCardPaysafeInstance = null;
    paysafeSelectedCardInitialized = false;
    paysafeFieldsErrorCode = '9003';
    states = observable.array([]);
    constructor() {
        makeObservable(this);
        this.states.replace(plainToInstance(ModelSate, jsonStates));
    }
    handleCardSaveOptionChange = () => {
        this.cardSaveOptionChecked = !this.cardSaveOptionChecked;
    };
    payByNewCreditCard = async (wdollarPackage, currentUser) => {
        let paymentResult = null;
        if (!this.btnDisabled) {
            this.setAddNewShowLoading(true);
            let merchantRefNum = '';
            try {
                const stateCode = this.selectedState.split('-').at(1);
                const data = {
                    offerId: +wdollarPackage.id,
                    ticket: wdollarPackage.ticket,
                    countryCode: 'US',
                    areaCode: stateCode,
                    postalCode: this.zip,
                    amount: this.testAmount ? this.testAmount : wdollarPackage.price
                };
                const response = await api.post(EApiUrl.createPaysafeOrder, data, {
                    headers: {
                        Authorization: currentUser?.token
                    }
                });
                if (response.status === 200 && response.data?.merchantRefNum) {
                    merchantRefNum = response.data?.merchantRefNum;
                    const tokenizationOptions = {
                        amount: +response.data?.amount,
                        merchantRefNum: merchantRefNum,
                        transactionType: "PAYMENT",
                        paymentType: "CARD",
                        customerDetails: {
                            billingDetails: {
                                country: "US",
                                zip: this.zip,
                                state: stateCode
                            }
                        }
                    };
                    if (this.cardHolder) {
                        // @ts-ignore
                        tokenizationOptions.customerDetails.holderName = this.cardHolder;
                    }
                    // @ts-ignore
                    paymentResult = await this.paysafeInstance.tokenize(tokenizationOptions);
                }
            }
            catch (error) {
                this.setAddNewShowLoading(false);
                if (error?.code === this.paysafeFieldsErrorCode) {
                    this.setPaysafeFieldsError(true);
                    this.handlePaysafeFieldsError(error);
                }
                else if (error.response.data.error) {
                    this.setPaysafeFieldsError(true);
                    this.handlePaysafeFieldsError(error.response.data.error);
                }
                else {
                    paymentResult = { transactionDeclined: true };
                }
            }
            try {
                if (paymentResult && !paymentResult?.transactionDeclined) {
                    paymentResult.cardHolder = this.cardHolder;
                    //paymentResult.isSaveCard = this.cardSaveOptionChecked;
                    const completeOrderData = { merchantRefNum: merchantRefNum, paymentHandleToken: paymentResult.token };
                    const completePaysafeOrderResponse = await api.post(EApiUrl.completePaysafeOrder, completeOrderData, {
                        headers: {
                            Authorization: currentUser?.token
                        }
                    });
                    if (completePaysafeOrderResponse.status === 200) {
                        // @ts-ignore
                        paymentResult.id = completePaysafeOrderResponse.data?.purchaseNumber;
                        paymentResult.paymentMethod = completePaysafeOrderResponse.data?.paymentMethod;
                        paymentResult.total = completePaysafeOrderResponse.data?.total;
                        paymentResult.packagePrice = completePaysafeOrderResponse.data?.items[0].amount;
                        paymentResult.packageFreePlay = completePaysafeOrderResponse.data?.items[1].amount;
                        this.setCardNumberValid(false);
                        this.btnDisabled = true;
                    }
                }
            }
            catch (error) {
                if (error.response.data.error) {
                    const errorCode = error.response.data.error.primary.code;
                    if (errorCode === 'payment_request_declined') {
                        paymentResult = { transactionDeclined: true };
                    }
                    else if (errorCode === 'payment_error') {
                        paymentResult = { completePurchaseError: true };
                    }
                    else {
                        this.setPaysafeFieldsError(true);
                        paymentResult = this.handlePaysafeFieldsError(error.response.data.error);
                    }
                }
                else {
                    paymentResult = { completePurchaseError: true };
                }
                this.setAddNewShowLoading(false);
                this.btnDisabled = true;
            }
        }
        return paymentResult;
    };
    generateGuid = () => {
        // @ts-ignore
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));
    };
    initPaysafe = async () => {
        this.clearAllErrors();
        const paysafeOptions = {
            currencyCode: 'USD',
            accounts: {
                default: +this.paysafeDefaultAccount
            },
            environment: this.paysafeEnv,
            fields: {
                cardNumber: {
                    selector: '#cardNumber',
                    separator: ' '
                },
                cvv: {
                    selector: '#cardCVC',
                    mask: false,
                    optional: false
                },
                expiryDate: {
                    selector: '#cardExpiry',
                    placeholder: 'MM / YY'
                }
            },
            style: {
                input: {
                    "color": '#777E90',
                    "font-size": '14px',
                    "line-height": '24px'
                }
            }
        };
        try {
            // @ts-ignore
            this.paysafeInstance = await paysafe.fields.setup(this.paysafejsApiKey, paysafeOptions);
            const paymentMethods = await this.paysafeInstance.show();
            this.addPaysafeEventListeners();
        }
        catch (error) {
            console.log(error);
        }
    };
    initSelectedCardPaysafe = async () => {
        if (!this.paysafeSelectedCardInitialized) {
            this.paysafeSelectedCardInitialized = true;
            const paysafeOptions = {
                currencyCode: 'USD',
                accounts: {
                    default: +this.paysafeDefaultAccount
                },
                environment: this.paysafeEnv,
                fields: {
                    cardNumber: {
                        selector: '#selectedCardNumber',
                        optional: true
                    },
                    cvv: {
                        selector: '#selectedCardCvc'
                    },
                    expiryDate: {
                        selector: '#selectedCardExpiry',
                        optional: true
                    }
                },
                style: {
                    input: {
                        "color": '#777E90',
                        "font-size": '14px',
                        "line-height": '24px'
                    }
                }
            };
            try {
                // @ts-ignore
                this.selectedCardPaysafeInstance = await paysafe.fields.setup(this.paysafejsApiKey, paysafeOptions);
                this.addSelectedPaysafeEventListeners();
            }
            catch (error) {
                console.log(error);
            }
        }
    };
    setBtnDisabled = (value) => {
        this.btnDisabled = value;
    };
    setCardNumberValid = (value) => {
        this.cardNumberValid = value;
    };
    setCardNumberError = (value) => {
        this.cardNumberError = value;
    };
    setExpiryDateError = (value) => {
        this.expiryDateError = value;
    };
    setCvcError = (value) => {
        this.cvcError = value;
    };
    addPaysafeEventListeners = () => {
        this.paysafeInstance.fields('cvv cardNumber expiryDate').valid((eventInstance, event) => {
            this.setPaysafeFieldsError(false);
            switch (event.target.fieldName) {
                case 'CardNumber':
                    if (eventInstance.getCardBrand()) {
                        this.setCardNumberError(false);
                        this.setCardNumberValid(true);
                        this.setCreditCardBrand(eventInstance.getCardBrand());
                        this.setCardNumberErrorText('');
                    }
                    else {
                        this.setCardNumberValid(false);
                        this.setCardNumberError(true);
                        this.setCardNumberErrorText('Invalid Card number');
                        this.setCreditCardBrand('');
                    }
                    break;
                case 'ExpiryDate':
                    this.setExpiryDateError(false);
                    this.setExpiryDateErrorText('');
                    this.setExpiryDateValid(true);
                    break;
                case 'Cvv':
                    this.setCvcError(false);
                    this.setCvcErrorText('');
                    this.setCvcValid(true);
                    break;
                default:
                    break;
            }
            this.validateAllFields();
        });
        this.paysafeInstance.fields('cvv cardNumber expiryDate').invalid((eventInstance, event) => {
            this.setBtnDisabled(true);
            switch (event.target.fieldName) {
                case 'CardNumber':
                    this.setCardNumberValid(false);
                    this.setCardNumberError(true);
                    this.setCardNumberErrorText('Invalid Card number');
                    this.setCreditCardBrand('');
                    break;
                case 'ExpiryDate':
                    this.setExpiryDateError(true);
                    this.setExpiryDateValid(false);
                    break;
                case 'Cvv':
                    this.setCvcError(true);
                    this.setCvcValid(false);
                    break;
                default:
                    break;
            }
        });
        this.paysafeInstance.fields('cvv cardNumber expiryDate').fieldValueChange((eventInstance, event) => {
            if (this.cardNumberError && eventInstance.fields.cardNumber.isEmpty()) {
                this.setCardNumberError(false);
                this.setCardNumberErrorText('');
            }
            if (this.cvcError && eventInstance.fields.cvv.isEmpty()) {
                this.setCvcError(false);
                this.setCvcErrorText('');
            }
            if (this.expiryDateError && eventInstance.fields.expiryDate.isEmpty()) {
                this.setExpiryDateError(false);
                this.setExpiryDateErrorText('');
            }
        });
        this.paysafeInstance.fields('cardNumber').blur((eventInstance, event) => {
            if (!eventInstance.fields.cardNumber.isEmpty() && !eventInstance.getCardBrand()) {
                this.setCardNumberValid(false);
                this.setCardNumberError(true);
                this.setCardNumberErrorText('Invalid Card number');
                this.setCreditCardBrand('');
            }
        });
    };
    addSelectedPaysafeEventListeners = () => {
        this.selectedCardPaysafeInstance.fields('cvv').valid((eventInstance, event) => {
            this.setConfirmAndPayDisabled(false);
        });
        this.selectedCardPaysafeInstance.fields('cvv').invalid((eventInstance, event) => {
            this.setConfirmAndPayDisabled(true);
        });
    };
    setConfirmAndPayDisabled = (value) => {
        this.confirmAndPayDisabled = value;
    };
    getUserCreditCards = async (currentUser) => {
        if (!this.creditCards.length) {
            try {
                /*const response: AxiosResponse = await api.get(EApiUrl.creditCard, {
                  headers: {
                    Authorization: currentUser ? `Bearer ${currentUser.token}` : ``
                  }
                });
                if (response.statusText?.toLowerCase() === 'ok') {
                  this.setCreditCards(response.data);
                  if (response.data && response.data.length === 1) {
                    this.selectedCreditCard = response.data[0];
                  }
                  setTimeout(() => {this.initSelectedCardPaysafe();}, 2000);
                }*/
                /*const data = [
                  {
                    id: '1',
                    cardNumber: '**** **** **** 1425',
                    cardExpiration: '12/2025',
                    cardType: 'Visa',
                    singleUseCustomerToken: 'PrwxyviVAe4Zau3',
                    paymentTokenFrom: 'PrwxyviVAe4Zau3'
                  }
                ];
                this.setCreditCards(data);
                this.selectedCreditCard = data[0];
                setTimeout(() => {this.initSelectedCardPaysafe();}, 2000);*/
            }
            catch (error) { }
        }
    };
    setCreditCards = (creditCards) => {
        // @ts-ignore
        this.creditCards.replace(creditCards);
    };
    payBySelectedCreditCard = async (wdollarPackage) => {
        this.setPaysafeErrorSelectedCard('');
        if (!this.confirmAndPayDisabled) {
            this.setAddSelectedCardShowLoading(true);
            let paymentResult = null;
            const tokenizationOptions = {
                amount: wdollarPackage.price,
                merchantRefNum: this.generateGuid(),
                transactionType: "PAYMENT",
                paymentType: "CARD",
                singleUseCustomerToken: this.selectedCreditCard.singleUseCustomerToken,
                paymentTokenFrom: this.selectedCreditCard.paymentTokenFrom
            };
            try {
                this.paysafeInstance.resetCardDetails();
                // @ts-ignore
                paymentResult = await this.selectedCardPaysafeInstance.tokenize(tokenizationOptions);
                //paymentResult = Promise.resolve({token: 'SCJu5YmbO8rWWTyc'});          
            }
            catch (error) {
                this.setAddSelectedCardShowLoading(false);
                if (error.detailedMessage) {
                    this.setPaysafeErrorSelectedCard(error.detailedMessage);
                }
            }
            return paymentResult;
        }
    };
    setPaysafeErrorSelectedCard = (value) => {
        this.paysafeErrorSelectedCard = value;
    };
    setAddSelectedCardShowLoading = (value) => {
        this.addSelectedCardShowLoading = value;
    };
    setAddNewShowLoading = (value) => {
        this.addNewShowLoading = value;
    };
    handleCardHolderChange = (event) => {
        this.isCardHolderInvalid = false;
        this.cardHolderErrorText = '';
        if (event.target.value && !/^[a-zA-Z\s]{2,}$/i.test(event.target.value)) {
            this.isCardHolderInvalid = true;
            this.cardHolderErrorText = event.target.value.length === 1 ? 'Minimum 2 letters' : 'Invalid format';
            this.cardHolder = '';
        }
        else {
            this.isCardHolderInvalid = false;
            this.cardHolder = event.target.value;
            this.cardHolderErrorText = '';
        }
        this.validateAllFields();
    };
    setCreditCardBrand = (value) => {
        this.creditCardBrand = value;
    };
    clearAllErrors = () => {
        this.paysafeErrorSelectedCard = '';
        this.cardNumberError = false;
        this.setCardNumberErrorText('');
        this.isCardHolderInvalid = false;
        this.cardHolderErrorText = '';
        this.cardHolder = '';
        this.expiryDateError = false;
        this.setExpiryDateErrorText('');
        this.cvcError = false;
        this.setCvcErrorText('');
        this.setCardNumberValid(false);
        this.creditCardBrand = '';
        this.setPaysafeFieldsError(false);
        this.selectedState = '0';
        this.zipError = false;
        this.zip = '';
        this.setExpiryDateValid(false);
        this.setCvcValid(false);
        this.stateError = false;
    };
    setPaysafeFieldsError = (value) => {
        this.paysafeFieldsError = value;
    };
    setExpiryDateErrorText = (value) => {
        this.expiryDateErrorText = value;
    };
    setCardNumberErrorText = (value) => {
        this.cardNumberErrorText = value;
    };
    setCvcErrorText = (value) => {
        this.cvcErrorText = value;
    };
    handlePaysafeFieldsError = (error) => {
        let paymentResult = null;
        if (error?.fieldErrors && error?.fieldErrors.length) {
            for (const item of error?.fieldErrors) {
                switch (item.field) {
                    case 'expiry date':
                    case 'expiry year':
                    case 'expiry month':
                        this.setExpiryDateErrorText('Enter a valid expiration date');
                        this.setExpiryDateError(true);
                        break;
                    case 'card number':
                        this.setCardNumberErrorText('Enter a valid card number');
                        this.setCardNumberError(true);
                        break;
                    case 'cvv':
                        this.setCvcErrorText('Enter the CVV on your card');
                        this.setCvcError(true);
                        break;
                }
            }
            ;
        }
        else if (error?.primary?.code) {
            switch (error.primary.code) {
                case 'cvv_check_failed':
                    this.setCvcErrorText('Enter the CVV on your card');
                    this.setCvcError(true);
                    paymentResult = { transactionDeclined: true };
                    break;
                case 'expiry_date_invalid':
                    this.setExpiryDateErrorText('Enter a valid expiration date');
                    this.setExpiryDateError(true);
                    break;
                case 'invalid_card_number':
                    this.setCardNumberErrorText('Enter a valid card number');
                    this.setCardNumberError(true);
                    break;
                case 'invalid_postal_code':
                    this.setZipError(true);
                    break;
            }
        }
        return paymentResult;
    };
    handleStateChange = (event) => {
        this.stateError = false;
        this.selectedState = event.target.value;
        this.validateAllFields();
    };
    validateAllFields = () => {
        if (!this.isCardHolderInvalid && this.cardHolder
            && !this.expiryDateError && !this.cvcError
            && this.selectedState !== '0' && this.zip
            && this.cardNumberValid && this.expiryDateValid && this.cvcValid) {
            this.setBtnDisabled(false);
        }
        else {
            this.setBtnDisabled(true);
        }
    };
    onZipKeyDown = (e) => {
        const numPadNumberKeyCodes = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 109];
        const tabBackDel = [8, 9, 46];
        const acv = [65, 67, 86];
        const macProblems = [86, 91, 93];
        if ((e.keyCode < 48 || (e.keyCode > 57 &&
            !numPadNumberKeyCodes.includes(e.keyCode)))
            && !tabBackDel.includes(e.keyCode) && (e.ctrlKey === false
            || ((e.ctrlKey === true || e.metaKey === true) && !acv.includes(e.keyCode)))) {
            if (macProblems.includes(e.keyCode)) {
                return;
            }
            e.preventDefault();
        }
    };
    onZipBlur = (value) => {
        if (value && /^\d{5}$/i.test(value)) {
            this.zip = value;
            this.zipError = false;
        }
        else if (value) {
            this.zip = '';
            this.zipError = true;
        }
        this.validateAllFields();
    };
    onZipChange = (value) => {
        if (value && /^\d{5}$/i.test(value)) {
            this.zip = value;
            this.setPaysafeFieldsError(false);
        }
        else {
            this.zip = '';
        }
        this.validateAllFields();
    };
    onZipFocus = () => {
        this.zipError = false;
    };
    handleTestAmountBlur = (event) => {
        this.testAmount = event.target.value;
    };
    setZipError = (value) => {
        this.zipError = value;
    };
    validateOnDisabledClick = () => {
        if (this.selectedState === '0') {
            this.stateError = true;
        }
        if (!this.cardNumberValid) {
            this.cardNumberError = true;
            this.setCardNumberErrorText('Enter a card number');
        }
        if (!this.expiryDateValid) {
            this.expiryDateError = true;
            this.setExpiryDateErrorText('Enter an expiration date');
        }
        if (!this.cvcValid) {
            this.cvcError = true;
            this.setCvcErrorText('Enter the CVV on your card');
        }
        if (!this.zip) {
            this.zipError = true;
        }
        if (!this.cardHolder) {
            this.isCardHolderInvalid = true;
            this.cardHolderErrorText = 'Enter a card holder';
        }
    };
    setExpiryDateValid = (value) => {
        this.expiryDateValid = value;
    };
    setCvcValid = (value) => {
        this.cvcValid = value;
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "cardNumberValid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "cardNumberError", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PaysafeFormService.prototype, "cardNumberErrorText", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "expiryDateValid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "expiryDateError", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PaysafeFormService.prototype, "expiryDateErrorText", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "cvcValid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "cvcError", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PaysafeFormService.prototype, "cvcErrorText", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "isCardHolderInvalid", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PaysafeFormService.prototype, "cardHolderErrorText", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "btnDisabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "cardSaveOptionChecked", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "addNewShowLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "addSelectedCardShowLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "confirmAndPayDisabled", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PaysafeFormService.prototype, "paysafeErrorSelectedCard", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PaysafeFormService.prototype, "creditCardBrand", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "paysafeFieldsError", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "zipError", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaysafeFormService.prototype, "stateError", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], PaysafeFormService.prototype, "selectedState", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "testAmount", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "handleCardSaveOptionChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "payByNewCreditCard", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setBtnDisabled", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setCardNumberValid", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setCardNumberError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setExpiryDateError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setCvcError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "addPaysafeEventListeners", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setConfirmAndPayDisabled", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setCreditCards", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setPaysafeErrorSelectedCard", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setAddSelectedCardShowLoading", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setAddNewShowLoading", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "handleCardHolderChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setCreditCardBrand", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "clearAllErrors", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setPaysafeFieldsError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setExpiryDateErrorText", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setCardNumberErrorText", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setCvcErrorText", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "handlePaysafeFieldsError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "handleStateChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "validateAllFields", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "onZipKeyDown", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "onZipBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "onZipChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "onZipFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "handleTestAmountBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setZipError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "validateOnDisabledClick", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setExpiryDateValid", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaysafeFormService.prototype, "setCvcValid", void 0);
PaysafeFormService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], PaysafeFormService);
