var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from 'mobx';
import { Injectable } from './service.base';
import moment from 'moment';
import { api } from '~/tools/api/api';
import { EApiUrl } from '~/tools/api/enums';
export let AccountService = class AccountService {
    isLinkAccountBtnDisabled = true;
    isLinkUnityEmailInvalid = false;
    isDateOfBirthInvalid = false;
    showLoading = false;
    isLinkUnityAuthError = false;
    historyNextCursor = '';
    showLoadingHistory = false;
    linkAccount = {};
    creditCards = observable.array([]);
    purchaseHistory = observable.array([]);
    otpData;
    constructor() {
        makeObservable(this);
    }
    handleUnityEmailBlur = (event) => {
        this.setLinkUnityAuthError(false);
        if (event.target.value) {
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)
                && !/^\d+$/i.test(event.target.value)) {
                this.isLinkUnityEmailInvalid = true;
                this.linkAccount.unityAccountEmail = null;
            }
            else {
                this.isLinkUnityEmailInvalid = false;
                this.linkAccount.unityAccountEmail = event.target.value;
            }
        }
        else {
            this.linkAccount.unityAccountEmail = null;
        }
        this.validateLinkAccountAllFields();
    };
    validateLinkAccountAllFields = () => {
        this.isLinkAccountBtnDisabled = (!this.linkAccount.unityAccountEmail || !this.linkAccount.dateBirth);
    };
    handleDateOfBirthChange = (event) => {
        if (event.target.value) {
            const dateArr = event.target.value.split('/');
            if (dateArr.length < 3) {
                this.isDateOfBirthInvalid = true;
            }
            else {
                const month = dateArr[0];
                const day = dateArr[1];
                const year = dateArr[2];
                const dateFormat = 'YYYY-MM-DD';
                const date = `${year}-${month}-${day}`;
                const toDateFormat = moment(new Date(date)).format(dateFormat);
                if (moment(toDateFormat, dateFormat, true).isValid()) {
                    const currentYear = new Date().getFullYear();
                    const allowYear = currentYear - 18;
                    const hunderYearAgo = currentYear - 100;
                    if (year > allowYear || year < hunderYearAgo) {
                        this.isDateOfBirthInvalid = true;
                    }
                    else {
                        this.linkAccount.dateBirth = event.target.value;
                        this.isDateOfBirthInvalid = false;
                    }
                }
                else {
                    this.isDateOfBirthInvalid = true;
                }
            }
        }
        else {
            this.linkAccount.dateBirth = null;
        }
        this.validateLinkAccountAllFields();
    };
    handleDateOfBirthLinkFocus = () => {
        this.isDateOfBirthInvalid = false;
    };
    linkUnityAccount = async (currentUser, verificationCode) => {
        let verifyResult = false;
        this.setShowLoading(true);
        try {
            const data = {
                otp: verificationCode,
                transactionId: this.otpData.transactionId,
                unityUserId: this.otpData.unityUserId
            };
            const response = await api.post(EApiUrl.unityOtp, data, {
                headers: {
                    Authorization: currentUser ? currentUser.token : ''
                }
            }, true);
            this.setShowLoading(false);
            if (response.status === 200 && !response.data?.error) {
                verifyResult = true;
            }
        }
        catch (error) {
            this.setShowLoading(false);
        }
        return verifyResult;
    };
    sendVerificationCode = async (token) => {
        let sendResult = false;
        this.setShowLoading(true);
        const email = this.linkAccount.unityAccountEmail && this.linkAccount.unityAccountEmail.includes('@') ? this.linkAccount.unityAccountEmail : null;
        const accountNumber = this.linkAccount.unityAccountEmail && !this.linkAccount.unityAccountEmail.includes('@') ? this.linkAccount.unityAccountEmail : null;
        const dateArr = this.linkAccount.dateBirth ? this.linkAccount.dateBirth.split('/') : [];
        const month = dateArr[0];
        const day = dateArr[1];
        const year = dateArr[2];
        const dateOfBirth = `${year}-${month}-${day}`;
        const url = `${EApiUrl.unitySignIn}`;
        const data = { accountNumber, email, dateOfBirth };
        try {
            const response = await api.post(url, data, {
                headers: {
                    Authorization: token
                }
            }, true);
            this.setShowLoading(false);
            if (response.status === 200) {
                sendResult = true;
                this.otpData = response.data;
            }
        }
        catch (error) {
            this.setShowLoading(false);
            this.setLinkUnityAuthError(true);
        }
        return sendResult;
    };
    setShowLoading = (value) => {
        this.showLoading = value;
    };
    setCreditCards = (creditCards) => {
        // @ts-ignore
        this.creditCards.replace(creditCards);
    };
    getUserCreditCards = async (currentUser) => {
        if (!this.creditCards.length) {
            try {
                /*const response: AxiosResponse = await api.get(EApiUrl.creditCard, {
                  headers: {
                    Authorization: currentUser ? `Bearer ${currentUser.token}` : ``
                  }
                });
                if (response.statusText?.toLowerCase() === 'ok') {
                  this.setCreditCards(response.data);
                }*/
                const data = [
                    {
                        id: '1',
                        cardNumber: '**** **** **** 1425',
                        cardExpiration: '12/2025',
                        cardType: 'Visa',
                        singleUseCustomerToken: 'PrwxyviVAe4Zau3',
                        paymentTokenFrom: 'PrwxyviVAe4Zau3'
                    }
                ];
                this.setCreditCards(data);
            }
            catch (error) { }
        }
    };
    handleRemoveCardClick = async (cardId, currentUser) => {
        try {
            /*const response: AxiosResponse = await api.delete(`${EApiUrl.creditCard}/${cardId}`, {
              headers: {
                Authorization: currentUser ? `Bearer ${currentUser.token}` : ``
              }
            });
            if (response.statusText?.toLowerCase() === 'ok') {
              this.getUserCreditCards(currentUser);
            }*/
            this.getUserCreditCards(currentUser);
        }
        catch (error) { }
    };
    setLinkUnityAuthError = (value) => {
        this.isLinkUnityAuthError = value;
    };
    openVerificationCodeDialog = async (verificationCodeDialogSrv, token) => {
        const sendResult = await this.sendVerificationCode(token);
        if (sendResult) {
            verificationCodeDialogSrv.handleOpen();
        }
    };
    onLinkAccountKeyUp = (e, verificationCodeDialogSrv, token) => {
        if (e.keyCode === 13 && !this.isLinkAccountBtnDisabled) {
            this.openVerificationCodeDialog(verificationCodeDialogSrv, token);
        }
    };
    getPurchaseHistory = async (token, loadMore = false) => {
        this.setShowLoadingHistory(true);
        try {
            const purchaseHistoryUrl = loadMore && this.historyNextCursor
                ? `${EApiUrl.purchaseHistory}?next=${this.historyNextCursor}`
                : EApiUrl.purchaseHistory;
            const response = await api.get(purchaseHistoryUrl, {
                headers: {
                    Authorization: token
                }
            });
            if (response.status === 200) {
                let transactions = [];
                for (const item of response.data.items) {
                    transactions.push({
                        transactionId: item.id,
                        date: this.formatDateToLocal(item.date),
                        transactionType: item.type,
                        description: item.description,
                        freeplay: item.freePlay,
                        dollarPackage: item.wdollar,
                        amount: item.amount,
                        status: item.status,
                        statusClassName: item.status ? item.status.toLowerCase() : ''
                    });
                }
                ;
                this.setHistoryNextCursor(response.data.nextCursor);
                this.setPurchaseHistory(transactions, loadMore);
            }
        }
        catch (error) { }
        this.setShowLoadingHistory(false);
    };
    setPurchaseHistory = (transactions, loadMore) => {
        if (loadMore) {
            const allTransactions = [...this.purchaseHistory, ...transactions];
            // @ts-ignore
            this.purchaseHistory.replace(allTransactions);
        }
        else {
            // @ts-ignore
            this.purchaseHistory.replace(transactions);
        }
    };
    setHistoryNextCursor = (value) => {
        this.historyNextCursor = value;
    };
    formatDateToLocal = (date) => {
        const d = new Date(date);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        // @ts-ignore
        const formattedDate = d.toLocaleDateString('en-GB', options).replace(',', '');
        return formattedDate;
    };
    setShowLoadingHistory = (value) => {
        this.showLoadingHistory = value;
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AccountService.prototype, "isLinkAccountBtnDisabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AccountService.prototype, "isLinkUnityEmailInvalid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AccountService.prototype, "isDateOfBirthInvalid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AccountService.prototype, "showLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AccountService.prototype, "isLinkUnityAuthError", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], AccountService.prototype, "historyNextCursor", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AccountService.prototype, "showLoadingHistory", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "handleUnityEmailBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "validateLinkAccountAllFields", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "handleDateOfBirthChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "handleDateOfBirthLinkFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "linkUnityAccount", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "setShowLoading", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "setCreditCards", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "setLinkUnityAuthError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "openVerificationCodeDialog", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "onLinkAccountKeyUp", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "getPurchaseHistory", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "setPurchaseHistory", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "setHistoryNextCursor", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AccountService.prototype, "setShowLoadingHistory", void 0);
AccountService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], AccountService);
