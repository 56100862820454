import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useService } from '~/services/service.base';
import { DialogService } from '~/services/service.dialog';
import { WrapperService } from '~/services/service.wrapper';
export const AlertDialog = observer((props) => {
    const dialogService = useService(DialogService);
    const wrapperService = useService(WrapperService);
    const { alertDialogSrv } = dialogService;
    return (_jsx(Dialog, { open: alertDialogSrv.open, className: "hr-modal-dialog", children: _jsx("div", { className: "modal-dialog modal-dialog-centered alert-dialog", role: "document", children: _jsx("div", { className: "modal-content", children: _jsx("div", { className: "modal-body", children: _jsxs("div", { className: "modalContent", children: [_jsx("h3", { children: props.headerText }), _jsx("p", { children: props.mainText }), _jsx("div", { className: "alert-dialog-actions", children: _jsx("button", { type: "button", className: "btn", onClick: alertDialogSrv.handleClose, children: "OK" }) }), _jsx("div", { className: "alert-contact-info", children: _jsx("a", { href: "mailto:info@hardrockgames.com", children: "contact support" }) })] }) }) }) }) }));
});
