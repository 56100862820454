var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { action, makeObservable, observable } from 'mobx';
import { Injectable } from './service.base';
import { api } from '~/tools/api/api';
import { EApiUrl } from '~/tools/api/enums';
export let RedeemDialogService = class RedeemDialogService {
    open = false;
    showStep2 = false;
    showStep1 = true;
    slideArrowLeftEnabled = false;
    slideArrowRightEnabled = true;
    showLoading = false;
    selectedLocation = '0';
    redeemAmounts = [];
    redeemLocations = [];
    selectedRedeem;
    currentUser;
    constructor() {
        makeObservable(this);
        const storage = window.sessionStorage.getItem('redeemDialog');
        if (storage) {
            this.openFromStorage(storage);
        }
    }
    handleOpen = async (currentUser) => {
        this.currentUser = currentUser;
        await this.getRedeemOptions(currentUser);
        this.addToSessionStorage({ currentUser });
        this.setOpen(true);
    };
    setOpen = (value) => {
        this.open = value;
    };
    handleClose = () => {
        this.open = false;
        this.showStep1 = true;
        this.showStep2 = false;
        this.removeFromSessionStorage();
    };
    handleOpenStep2 = async () => {
        if (this.currentUser) {
            try {
                this.setShowLoading(true);
                const redeemData = {
                    amount: this.selectedRedeem.value,
                    locationCode: this.selectedLocation
                };
                const response = await api.post(EApiUrl.redeem, redeemData, {
                    headers: {
                        Authorization: this.currentUser.token
                    }
                });
                this.setShowLoading(false);
                if (response.status === 200) {
                    const currentFreePlay = parseFloat(this.currentUser.freePlay) - parseFloat(this.selectedRedeem.value);
                    this.currentUser.freePlay = currentFreePlay.toFixed(2);
                    this.setShowStep1(false);
                    this.setShowStep2(true);
                    this.getRedeemOptions(this.currentUser);
                }
            }
            catch (error) {
                this.setShowLoading(false);
            }
        }
    };
    handleOpenStep1 = () => {
        this.showStep1 = true;
        this.showStep2 = false;
    };
    setShowLoading = (value) => {
        this.showLoading = value;
    };
    setShowStep1 = (value) => {
        this.showStep1 = value;
    };
    setShowStep2 = (value) => {
        this.showStep2 = value;
    };
    handleLocationChange = (event) => {
        this.setSelectedLocation(event.target.value);
        this.addToSessionStorage({
            currentUser: this.currentUser,
            selectedRedeem: this.selectedRedeem,
            selectedLocation: this.selectedLocation
        });
    };
    setRedeemAmounts = (redeemAmounts) => {
        this.redeemAmounts = redeemAmounts;
    };
    setRedeemLocations = (redeemLocations) => {
        this.redeemLocations = redeemLocations;
    };
    handleSlideChange = (swiper) => {
        if (swiper.isBeginning) {
            this.slideArrowLeftEnabled = false;
        }
        else {
            this.slideArrowLeftEnabled = true;
        }
        if (swiper.isEnd) {
            this.slideArrowRightEnabled = false;
        }
        else {
            this.slideArrowRightEnabled = true;
        }
    };
    handleSlideClick = (redeemAmount) => {
        if (!redeemAmount.disabled) {
            const currentActive = this.redeemAmounts.find(item => item.active === true);
            if (currentActive) {
                currentActive.active = false;
            }
            this.setSelectedRedeem(redeemAmount);
            redeemAmount.active = true;
            this.addToSessionStorage({ currentUser: this.currentUser, selectedRedeem: this.selectedRedeem });
        }
    };
    getSelectedLocation = () => {
        const selectedLocation = this.redeemLocations.find(item => item.code === this.selectedLocation);
        return selectedLocation ? selectedLocation.name : '';
    };
    onRedeemKeyUp = (e) => {
        if (e.keyCode === 13 && this.selectedRedeem && this.selectedLocation !== '0') {
            this.handleOpenStep2();
        }
    };
    setSelectedRedeem = (redeemAmount) => {
        this.selectedRedeem = redeemAmount;
    };
    setSelectedLocation = (selectedLocation) => {
        this.selectedLocation = selectedLocation;
    };
    addToSessionStorage = (data) => {
        window.sessionStorage.setItem('redeemDialog', JSON.stringify(data));
    };
    openFromStorage = async (storage) => {
        const storageData = JSON.parse(storage);
        if (storageData?.currentUser) {
            this.open = true;
            this.currentUser = storageData.currentUser;
            await this.getRedeemOptions(this.currentUser);
            if (storageData?.selectedRedeem) {
                setTimeout(() => {
                    this.setSelectedRedeem(storageData.selectedRedeem);
                    const redeem = this.redeemAmounts.find(item => item.value === this.selectedRedeem.value);
                    if (redeem) {
                        redeem.active = true;
                    }
                }, 2000);
            }
            if (storageData?.selectedLocation) {
                this.setSelectedLocation(storageData?.selectedLocation);
            }
        }
    };
    removeFromSessionStorage = () => {
        window.sessionStorage.removeItem('redeemDialog');
    };
    getRedeemOptions = async (currentUser) => {
        if (currentUser) {
            try {
                const response = await api.get(EApiUrl.redeemOptions, {
                    headers: {
                        Authorization: currentUser.token
                    }
                });
                if (response.status === 200) {
                    const userTotalBalance = +currentUser.freePlay;
                    const redeemAmounts = [];
                    for (const item of response.data?.amounts) {
                        redeemAmounts.push({
                            value: +item,
                            disabled: !response.data?.selectable.includes(item),
                            active: false
                        });
                    }
                    this.setRedeemAmounts(redeemAmounts);
                    const locations = [];
                    for (const item of response.data?.locations) {
                        locations.push({
                            code: item.code,
                            name: item.display_name
                        });
                    }
                    this.setRedeemLocations(locations);
                }
            }
            catch (error) { }
        }
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RedeemDialogService.prototype, "open", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RedeemDialogService.prototype, "showStep2", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RedeemDialogService.prototype, "showStep1", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RedeemDialogService.prototype, "slideArrowLeftEnabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RedeemDialogService.prototype, "slideArrowRightEnabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], RedeemDialogService.prototype, "showLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], RedeemDialogService.prototype, "selectedLocation", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object)
], RedeemDialogService.prototype, "redeemAmounts", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_b = typeof Array !== "undefined" && Array) === "function" ? _b : Object)
], RedeemDialogService.prototype, "redeemLocations", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "selectedRedeem", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "handleOpen", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "setOpen", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "handleClose", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "handleOpenStep2", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "handleOpenStep1", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "setShowLoading", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "setShowStep1", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "setShowStep2", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "handleLocationChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "setRedeemAmounts", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "setRedeemLocations", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "handleSlideChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "handleSlideClick", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "getSelectedLocation", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "onRedeemKeyUp", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "setSelectedRedeem", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "setSelectedLocation", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "openFromStorage", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], RedeemDialogService.prototype, "getRedeemOptions", void 0);
RedeemDialogService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], RedeemDialogService);
