var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, makeObservable, observable } from 'mobx';
import { plainToInstance } from 'class-transformer';
import { api } from '~/tools/api/api';
import { EApiUrl } from '~/tools/api/enums';
import { ModelUnityUser } from '~/models/model.unity.user';
import { ModelUser } from '~/models/model.user';
export class AuthService {
    currentUser;
    showUnityBanner = true;
    showBalance = false;
    loading = true;
    redirectToHome = false;
    constructor() {
        makeObservable(this);
    }
    initUser = async () => {
        //const userToken: any = Cookies.get('userToken');
        const userToken = window.sessionStorage.getItem('userToken');
        if (userToken) {
            await this.getUser(userToken);
            await this.getUserBalance();
            await this.getUnityUser();
        }
        else {
            this.setRedirectToHome(true);
            this.setLoading(false);
        }
    };
    getUnityUser = async () => {
        if (this.currentUser?.token) {
            try {
                const response = await api.get(EApiUrl.unityUserSummary, {
                    headers: {
                        Authorization: this.currentUser?.token
                    }
                }, true);
                if (response.status === 200 && response.data.summary && response.data.summary.accountNumber) {
                    this.setUnityData(plainToInstance(ModelUnityUser, response.data));
                }
            }
            catch (error) { }
        }
    };
    getUser = async (token) => {
        try {
            const response = await api.get(EApiUrl.profile, {
                headers: {
                    Authorization: token
                }
            });
            if (response.status === 200) {
                response.data.token = token;
                response.data.isUnityLinked = false;
                this.setCurrentUser(plainToInstance(ModelUser, response.data));
                this.setLoading(false);
            }
        }
        catch (error) { }
    };
    getUserBalance = async () => {
        if (this.currentUser?.token) {
            try {
                const response = await api.get(EApiUrl.userBalance, {
                    headers: {
                        Authorization: this.currentUser?.token
                    }
                });
                if (response.status === 200) {
                    this.currentUser.balance = response.data.wdollars;
                    this.currentUser.freePlay = response.data.freeplay;
                    this.setShowBalance(true);
                }
            }
            catch (error) { }
        }
    };
    setShowBalance = (value) => {
        this.showBalance = value;
    };
    setLoading = (value) => {
        this.loading = value;
    };
    setRedirectToHome = (value) => {
        this.redirectToHome = value;
    };
    login = async (token) => {
        try {
            const response = await api.get(EApiUrl.profile, {
                headers: {
                    Authorization: token
                }
            });
            if (response.status === 200) {
                response.data.token = token;
                /*const date = new Date();
                date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
      
                this.setUserSession({name: 'userToken', token}, {path: '/', expires: date});*/
                this.setUserSession(token);
                this.setCurrentUser(plainToInstance(ModelUser, response.data));
                this.setLoading(false);
                await this.getUserBalance();
                this.getUnityUser();
                this.setRedirectToHome(false);
            }
        }
        catch (error) { }
    };
    logout = () => {
        //Cookies.remove('userToken');
        window.sessionStorage.removeItem('userToken');
        this.setCurrentUser(null);
        this.showUnityBanner = true;
        this.setRedirectToHome(true);
    };
    setCurrentUser = (user) => {
        this.currentUser = user;
    };
    setUnityData = (unityUser) => {
        if (this.currentUser && unityUser?.summary?.accountNumber) {
            this.showUnityBanner = false;
            this.currentUser.totalUnityPoints = unityUser.summary.totalUnityPoints;
            this.currentUser.unityAccountNumber = unityUser.summary.accountNumber;
            this.currentUser.firstName = unityUser.summary.firstName;
            this.currentUser.lastName = unityUser.summary.lastName;
            this.currentUser.email = unityUser.summary.emailAddress;
            this.currentUser.unityTier = unityUser.status.unityTier;
            this.currentUser.totalTierCredits = unityUser.summary.totalTierCredits;
        }
    };
    /*setUserSession = (userSession: IUserSession, options?: CookieAttributes) => {
      Cookies.set(userSession.name, userSession.token, options);
    }*/
    setUserSession = (token) => {
        window.sessionStorage.setItem('userToken', token);
    };
}
__decorate([
    observable,
    __metadata("design:type", Object)
], AuthService.prototype, "currentUser", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AuthService.prototype, "showUnityBanner", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AuthService.prototype, "showBalance", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AuthService.prototype, "loading", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AuthService.prototype, "redirectToHome", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "initUser", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "getUnityUser", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "getUser", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "getUserBalance", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "setShowBalance", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "setLoading", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "setRedirectToHome", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "login", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "logout", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "setCurrentUser", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AuthService.prototype, "setUnityData", void 0);
